import { Environment } from "../../../environment";
import { IMessage } from "../../../types/message";
import { Api } from "../axios-config";

let formData = null;

export interface IListagemContract {
  id: string;
  title: string;
  statusContract: string;
  description: string;
  platform: string;
  userId: string;
  uploaded_at: string;
  filename: string;
  mimetype: string;
  original_name: string;
  pathfile: string;
  docKey?: string;
  version?: string;
  createdAt: string;
  updatedAt?: string;
}

export interface IDetalheContract {
  id?: string;
  title: string;
  statusContract?: string;
  description: string;
  platform: string;
  userId?: string;
  uploaded_at?: string;
  filename?: string;
  mimetype?: string;
  original_name?: string;
  pathfile?: string;
  docKey?: string;
  version?: string;
  createdAt: string;
  updatedAt?: string;
}

type TContractTotalCount = {
  results: IListagemContract[];
  total: number;
};

export type TContractPromise = string | Error | undefined;

export type TContractAllPromise = TContractTotalCount | Error;

const getAll = async (
  page: number = 1,
  title: string = "",
  status: string = "",
  dataCreate: string = ""
): Promise<TContractAllPromise> => {
  try {
    const urlRelativa = `/contract?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&title=${title}&status=${status}&dataCreate=${dataCreate}`;

    const { data } = await Api().get(urlRelativa);

    if (data.results) return data;

    return new Promise((_, reject) =>
      reject(new Error("Erro ao listar registros."))
    );
  } catch (error: any) {
    console.error(error);
    throw new Promise((_, reject) => reject(error));
  }
};

const getDocumentContract = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api().get(`/contract/report/${id}`);

    if (!data)
      return new Promise((_, reject) =>
        reject(new Error("Erro ao visualizar o contrato."))
      );

    console.log(data.preSingedUrl);
    const link = document.createElement('a');
    link.href = data.preSignedUrl;
    link.download = data.filename;
    link.target = '_blank'; // Para abrir em uma nova aba, opcional
    link.rel = 'noopener noreferrer'; // Para segurança adicional

    // Simula o clique no link
    document.body.appendChild(link);
    link.click();

    // Remove o elemento após o clique
    document.body.removeChild(link);

  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const shootQueue = async (id: string): Promise<IMessage<null> | Error> => {

  try {
    await Api().post("/contract/queue", { id });

    return new Promise((resolve) =>
      resolve({
        message: "Sucesso!",
        description: "Disparo para a fila realizado com sucesso!",
      })
    );

  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const getByID = async (id: string): Promise<IDetalheContract | Error> => {
  try {
    const { data } = await Api().get(`/contract/${id}`);
    if (data) return data;

    return new Promise((_, reject) =>
      reject(new Error("Erro ao listar registro."))
    );
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const create = async (
  dados: Omit<IDetalheContract, "id">,
  file: File | undefined
): Promise<TContractPromise> => {
  try {
    formData = new FormData();

    formData.append("title", dados.title);
    formData.append("description", dados.description);
    formData.append("platform", dados.platform);

    if (file) formData.append("file", file);

    const { data } = await Api().post("/contract", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data.id;
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

const updateById = async (
  id: string,
  dados: IDetalheContract
): Promise<void | Error> => {
  try {
    await Api().patch(`/contract/${id}`, dados);
  } catch (error: any) {
    console.error(error);
    return new Promise((_, reject) => reject(error));
  }
};

// const deleteById = async (id: string): Promise<void | Error> => {
//   try {
//     await Api().delete(`/contract/${id}`);
//   } catch (error: any) {
//     console.error(error);
//     return new Promise((_, reject) => reject(error));
//   }
// };

export const contractService = {
  getAll,
  getByID,
  create,
  updateById,
  getDocumentContract,
  shootQueue,
  //deleteById,
};
